import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a679803e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "announcement" }
const _hoisted_2 = { class: "announcement__time" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "announcement__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.announcement.time), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
        class: _normalizeClass(['announcement__icon', _ctx.playOrPauseClass]),
        "aria-label": _ctx.isOnPause ? _ctx.PAUSE_BUTTON_LABEL : _ctx.PLAY_BUTTON_LABEL
      }, null, 10, _hoisted_3)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.announcementDescription), 1)
  ]))
}