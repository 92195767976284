import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "journey-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_journey_item = _resolveComponent("a-journey-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.journeyItems, (item) => {
      return (_openBlock(), _createBlock(_component_a_journey_item, {
        key: `${item.id}-${item.scheduledTime}`,
        "allow-navigation": item.allowNavigation,
        title: item.title,
        platform: item.platform,
        "actual-time": item.actualTime,
        "scheduled-time": item.scheduledTime
      }, null, 8, ["allow-navigation", "title", "platform", "actual-time", "scheduled-time"]))
    }), 128))
  ]))
}