
import { defineComponent, toRefs, computed, PropType } from 'vue'

export enum ICON_TYPE {
  DEPARTURES = 'DEPARTURES',
  FACILITIES = 'FACILITIES',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  SURVEY = 'SURVEY',
  NAVIGATE = 'NAVIGATE',
  NAVIGATE_BLUE = 'NAVIGATE_BLUE',
  NAVIGATE_SM = 'NAVIGATE_SM',
  RESTAURANTS = 'RESTAURANTS',
  ASSISTANCE_DESK = 'ASSISTANCE_DESK',
  DISABLED_TOILETS = 'DISABLED_TOILETS',
  TICKETS = 'TICKETS',
  TOILETS = 'TOILETS',
  EXIT = 'EXIT',
  PRIORITY_LOUNGE = 'PRIORITY_LOUNGE',
  WARNING = 'WARNING',
  NEXT = 'NEXT',
  VIDEOS_GUIDE = 'VIDEOS_GUIDE'
}

const iconTypeList: ICON_TYPE[] = Object.values(ICON_TYPE)

const ICON_MAP: { [key in ICON_TYPE]: string } = {
  [ICON_TYPE.DEPARTURES]: '/icons/ic-departures.svg',
  [ICON_TYPE.FACILITIES]: '/icons/ic-facilities.svg',
  [ICON_TYPE.ANNOUNCEMENTS]: '/icons/ic-announcements.svg',
  [ICON_TYPE.SURVEY]: '/icons/ic-survey.svg',
  [ICON_TYPE.NAVIGATE]: '/icons/ic-navigate.svg',
  [ICON_TYPE.NAVIGATE_BLUE]: '/icons/ic-navigate-blue.svg',
  [ICON_TYPE.NAVIGATE_SM]: '/icons/ic-navigate-sm.svg',
  [ICON_TYPE.RESTAURANTS]: '/icons/ic-restaurants.svg',
  [ICON_TYPE.ASSISTANCE_DESK]: '/icons/ic-assistance-desk.svg',
  [ICON_TYPE.DISABLED_TOILETS]: '/icons/ic-disabled-toilets.svg',
  [ICON_TYPE.EXIT]: '/icons/ic-exit.svg',
  [ICON_TYPE.PRIORITY_LOUNGE]: '/icons/ic-priority-lounge.svg',
  [ICON_TYPE.TICKETS]: '/icons/ic-tickets.svg',
  [ICON_TYPE.TOILETS]: '/icons/ic-toilets.svg',
  [ICON_TYPE.WARNING]: '/icons/ic-warning.svg',
  [ICON_TYPE.NEXT]: '/icons/ic-next.svg',
  [ICON_TYPE.VIDEOS_GUIDE]: '/icons/ic-videos-guide.svg'
}

export default defineComponent({
  name: 'ACardIcon',
  props: {
    iconType: {
      type: String as PropType<ICON_TYPE>,
      required: true,
      validator(val: ICON_TYPE) {
        return iconTypeList.includes(val)
      }
    }
  },
  setup(props) {
    const { iconType } = toRefs(props) as any

    const iconURL = computed(() => ICON_MAP[iconType.value])

    return {
      iconURL
    }
  }
})
