import {
  CallingPointTimes,
  NameI18n,
  DisplayCallingPoint
} from '@gomedia-apis-ts-pis/v1'
import {
  GetDeparturesResponse,
  TrainDeparture
} from '@gomedia-apis-ts-pis/v1/api'

enum DEPARTURE_TYPE {
  TRAIN = 'train_departures',
  AIRPORT = 'airport_departures'
}

enum CALLING_POINT_TIME {
  SCHEDULED = 'scheduled_time',
  ESTIMATED = 'estimated_time',
  ACTUAL = 'actual_time'
}

type FormattedTime = { scheduledTime: string; actualTime: string }

const formatDate = (
  date: Date | number,
  timeZone = 'Europe/London'
): string => {
  return new Intl.DateTimeFormat('en-GB', {
    timeZone,
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

const getDepartureTimes = (departureTimes: CallingPointTimes) => {
  const scheduledTime = departureTimes[CALLING_POINT_TIME.SCHEDULED]
  const actualTime =
    departureTimes[CALLING_POINT_TIME.ACTUAL] ||
    departureTimes[CALLING_POINT_TIME.ESTIMATED]

  return {
    scheduledTime,
    actualTime
  }
}

export const getFormattedTimes = (
  departureTimes?: CallingPointTimes,
  timeZone?: string
): FormattedTime => {
  if (!departureTimes) {
    return {
      scheduledTime: '',
      actualTime: ''
    }
  }

  const { scheduledTime, actualTime } = getDepartureTimes(departureTimes)

  return {
    scheduledTime: scheduledTime
      ? formatDate(new Date(scheduledTime), timeZone)
      : '',
    actualTime: actualTime ? formatDate(new Date(actualTime), timeZone) : ''
  }
}

export const getTrainDeparturesFromResponse = (
  response: GetDeparturesResponse
): TrainDeparture[] => {
  const departure = response.departures?.find(
    (item) => item[DEPARTURE_TYPE.TRAIN]
  )
  if (departure) {
    return departure[DEPARTURE_TYPE.TRAIN]?.departures || []
  }
  return []
}

export const getNameI18n = (nameI18n?: NameI18n, language = 'en'): string => {
  if (!nameI18n || !nameI18n.values) return ''

  return nameI18n.values[language] || ''
}

export const getCallingPointId = (
  callingPoint: DisplayCallingPoint
): string => {
  return callingPoint?.calling_point?.id?.id || ''
}

export const getDestinationName = (
  callingPoints: DisplayCallingPoint[]
): string => {
  const lastItem = callingPoints[callingPoints.length - 1]
  return getNameI18n(lastItem?.display_name)
}
