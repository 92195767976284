
import { defineComponent } from 'vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'
import { useStationConfigInjection } from '@/hooks/useStationConfig'

export default defineComponent({
  name: 'AHeader',
  components: {
    AStationHeader
  },
  setup() {
    const { showStationHeader } = useStationConfigInjection()
    return {
      showStationHeader
    }
  }
})
