
import { computed, defineComponent, PropType } from 'vue'
import { PlayStatus } from '@/hooks/useVoiceSpeech'
import { StationAnnouncement } from '@gomedia-apis-ts-pis/v1/api'
import { getNameI18n } from '@/helpers/departures'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'

const PLAY = 'announcement__icon--play'
const PAUSE = 'announcement__icon--pause'
const PLAY_BUTTON_LABEL = 'Play button'
const PAUSE_BUTTON_LABEL = 'Pause button'

export default defineComponent({
  name: 'AnnouncementItem',
  props: {
    announcement: {
      type: Object as PropType<StationAnnouncement>,
      required: true
    },
    playStatus: {
      type: String as PropType<PlayStatus>
    }
  },
  setup(props, { emit }) {
    const isOnPause = computed(() => props.playStatus === PlayStatus.PAUSE)

    const playOrPauseClass = computed(() => {
      return isOnPause.value ? PAUSE : PLAY
    })

    const announcementDescription = computed((): string =>
      getNameI18n(props.announcement.message)
    )

    function sendAnalytics() {
      analytics.sendEvent(AnalyticsEvents.TEXT_TO_SPEECH, {
        text: `${props.announcement.time}. ${announcementDescription.value}`
      })
    }

    function handleClick() {
      if (!isOnPause.value) {
        sendAnalytics()
      }
      emit('convertTextToSpeech')
    }

    return {
      handleClick,
      playOrPauseClass,
      announcementDescription,
      isOnPause,
      PLAY_BUTTON_LABEL,
      PAUSE_BUTTON_LABEL
    }
  }
})
