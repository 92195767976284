import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-container grid-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_link_card = _resolveComponent("a-link-card")!
  const _component_a_departures_widget = _resolveComponent("a-departures-widget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.to.name === _ctx.ROUTE_NAME.DEPARTURES)
          ? (_openBlock(), _createBlock(_component_a_departures_widget, {
              key: item.to.name,
              "station-id": _ctx.currentStationId
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_link_card, {
                  to: item.to,
                  title: item.title,
                  "warning-message": item.warningMessage,
                  "icon-type": item.iconType
                }, null, 8, ["to", "title", "warning-message", "icon-type"])
              ]),
              _: 2
            }, 1032, ["station-id"]))
          : (_openBlock(), _createBlock(_component_a_link_card, {
              key: item.to.name,
              to: item.to,
              title: item.title,
              "warning-message": item.warningMessage,
              "icon-type": item.iconType
            }, null, 8, ["to", "title", "warning-message", "icon-type"]))
      ], 64))
    }), 256))
  ]))
}