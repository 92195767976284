export enum ROUTE_NAME {
  HOME = 'HOME',
  DIRECTIONS = 'DIRECTIONS',
  DEPARTURES = 'DEPARTURES',
  STATION_FACILITIES = 'STATION_FACILITIES',
  STATION_ANNOUNCEMENTS = 'STATION_ANNOUNCEMENTS',
  SHOPS = 'SHOPS',
  INFO_PAGE = 'INFO_PAGE',
  NAVIGATION_CONFIRMATION = 'NAVIGATION_CONFIRMATION',
  JOURNEY = 'JOURNEY',
  VIDEO_GUIDE = 'VIDEO_GUIDE',

  WEB_DEPARTURES = 'WEB_DEPARTURES',
  WEB_JOURNEY = 'WEB_JOURNEY'
}
