
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AAsyncLoader',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String
    }
  },
  setup(props) {
    const containerClass = computed(() =>
      !props.isLoading && !props.errorMessage
        ? 'async-container__content'
        : 'async-container align-center'
    )

    return {
      containerClass
    }
  }
})
