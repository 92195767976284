import { RouteRecordRaw } from 'vue-router'
import { createStore } from 'vuex'

import { TrainDeparture, Journey } from '@gomedia-apis-ts-pis/v1/api'

import { getTrainDeparturesFromResponse } from '@/helpers/departures'
import * as types from './types'
import {
  APIService,
  RequestParams,
  RequestWithTypeParams,
  VideoRequestParams
} from '@/services/api'

const api = new APIService()

export default createStore({
  state: {
    routeHistory: [] as RouteRecordRaw[]
  },
  getters: {
    lastRouteName(state): string {
      const last = state.routeHistory[state.routeHistory.length - 1]
      return last?.name?.toString() || ''
    }
  },
  actions: {
    async requestAllDepartures(_, params): Promise<TrainDeparture[]> {
      const res = await api.getAllDepartures(params)
      return getTrainDeparturesFromResponse(res)
    },
    requestJourney(_, params): Promise<Journey> {
      return api.getJourney(params)
    },
    async requestFacilities(_, params: RequestWithTypeParams) {
      const { items } = await api.getFacilities(params)
      return items
    },
    async requestDeparturesVideos(_, params: VideoRequestParams) {
      const { items } = await api.getDeparturesVideos(params)
      return items
    },
    async requestStationAnnouncements(_, params: RequestParams) {
      const { items } = await api.getStationAnnouncements(params)
      return items
    },
    async requestScheduledStationAnnouncements(_, params: RequestParams) {
      const { items } = await api.getScheduledStationAnnouncements(params)
      return items
    },
    updateRouteHistory({ commit }, route: RouteRecordRaw) {
      commit(types.UPDATE_ROUTE_HISTORY, route)
    },
    clearRouteHistory({ commit }) {
      commit(types.CLEAR_ROUTE_HISTORY)
    }
  },
  mutations: {
    [types.UPDATE_ROUTE_HISTORY](state, route) {
      if (route) {
        state.routeHistory.push(route)
      } else {
        state.routeHistory = state.routeHistory.slice(
          0,
          state.routeHistory.length - 1
        )
      }
    },
    [types.CLEAR_ROUTE_HISTORY](state) {
      state.routeHistory = []
    }
  }
})
