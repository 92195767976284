
import { getClosedMessage } from '@/helpers/facilities'
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'

import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'
import { useFacilities } from '@/hooks/useFacilities'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { usePageTitle } from '@/hooks/usePageTitle'

import { FacilityType } from '@/enums/facilities'
import { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import ALinkCard, { LinkCard } from '@/components/a-link-card/link-card.vue'
import ASubheader from '@/components/a-subheader/subheader.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'

function compareFacilityTitles(a: LinkCard, b: LinkCard) {
  const titleA = a.title.toUpperCase()
  const titleB = b.title.toUpperCase()
  if (titleA < titleB) {
    return -1
  }
  if (titleA > titleB) {
    return 1
  }
  return 0
}

export default defineComponent({
  name: 'StationFacilities',
  components: {
    AAsyncLoader,
    ALinkCard,
    ASubheader,
    AStationHeader
  },
  props: {
    type: {
      type: String as PropType<FacilityType>,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { currentStationId } = useStationIdInjection()
    const route = useRoute()
    const { isLoading, errorMessage, facilities } = useFacilities(
      currentStationId,
      props.type
    )
    const { disableNavigation, menuItems, redirectToFacilitiesPage } =
      useStationConfigInjection()

    const title = computed(
      () =>
        menuItems.find((item) =>
          typeof item.to === 'string'
            ? item.to === route.name
            : item.to.name === route.name
        )?.title ?? props.title
    )

    const facilityCards = computed<LinkCard[]>(() => {
      const cards = facilities.value.map((el) => {
        const closeWarning = getClosedMessage(el.working_hours)
        const to = el.link || {
          name: ROUTE_NAME.INFO_PAGE,
          params: {
            [ROUTE_PARAMS.INFO_PAGE.PAGE_ID]: el.id || '',
            [ROUTE_PARAMS.INFO_PAGE.TYPE]: props.type
          }
        }

        return {
          to,
          destinationId: el.name || '',
          iconType: el.icon_type as ICON_TYPE,
          title: el.name || '',
          titleTextToRead: `${el.name} information`,
          inactive: !el.active || Boolean(closeWarning),
          warningMessage: el.warning_message || closeWarning,
          showNavigateButton: Boolean(el.navigation_mode)
        }
      })
      return cards.sort(compareFacilityTitles)
    })

    usePageTitle(title)

    return {
      ICON_TYPE,
      isLoading,
      errorMessage,
      facilityCards,
      disableNavigation,
      title,
      redirectToFacilitiesPage
    }
  }
})
