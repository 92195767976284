
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { navigateBack } from '@/helpers/route-history'
import { requestNavigation } from '@/helpers/navilens-bridge'
import { useStationIdInjection } from '@/hooks/useStationId'
import { usePageTitle } from '@/hooks/usePageTitle'

import AButton from '@/components/a-button/button.vue'
import AFooter from '@/components/a-footer/footer.vue'

export default defineComponent({
  name: 'NavigationConfirmation',
  components: { AButton, AFooter },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const destinationName = ref(
      route.params[ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME]
    )
    const destinationID = ref(
      route.params[ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_ID]
    )
    const pageTitle = ref('Navigation confirmation')
    const { currentStationId, market } = useStationIdInjection()

    usePageTitle(pageTitle)

    const getAnalyticsParams = () => ({
      market,
      destination_name: destinationName.value,
      destination_id: destinationID.value
    })

    const submit = () => {
      analytics.sendEvent(
        AnalyticsEvents.CONFIRM_NAVIGATION,
        getAnalyticsParams()
      )
      requestNavigation(
        market,
        currentStationId,
        destinationID.value as string,
        destinationName.value as string
      )
    }

    const cancel = () => {
      analytics.sendEvent(
        AnalyticsEvents.DECLINE_NAVIGATION,
        getAnalyticsParams()
      )
      navigateBack()
    }

    if (!destinationID.value || !destinationName.value) {
      router.push({ name: ROUTE_NAME.HOME })
    }

    return {
      destinationName,
      destinationID,
      cancel,
      submit
    }
  }
})
