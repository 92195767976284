
import { defineComponent } from 'vue'

import { useLayoutConfigInjection } from '@/hooks/useLayoutConfig'

import SubheaderBar from './subheader-bar.vue'

export default defineComponent({
  name: 'ASubheader',
  components: { SubheaderBar },
  props: {
    hideBackButton: {
      type: Boolean
    },
    title: {
      type: String,
      required: true
    },
    showVideoBtn: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { showBackButton } = useLayoutConfigInjection()

    return { showBackButton }
  }
})
