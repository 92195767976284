
import { FacilityType } from '@/enums/facilities'
import { computed, defineComponent, PropType } from 'vue'

import { usePageTitle } from '@/hooks/usePageTitle'
import { useFacilities } from '@/hooks/useFacilities'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'
import { useRouter } from 'vue-router'
import { getGroupedWorkingHours } from '@/helpers/facilities'

import ASubheader from '@/components/a-subheader/subheader.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import AButton from '@/components/a-button/button.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'

export default defineComponent({
  name: 'InfoPage',
  components: { AButton, ASubheader, AAsyncLoader, AStationHeader },
  props: {
    pageId: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<FacilityType>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const { currentStationId } = useStationIdInjection()
    const { isLoading, facilities, errorMessage } = useFacilities(
      currentStationId,
      props.type
    )

    const facility = computed(() =>
      facilities.value.find((el) => el.id === props.pageId)
    )

    const { disableNavigation, signLanguage } = useStationConfigInjection()

    const isButtonVisible = computed(() =>
      Boolean(facility.value?.navigation_mode && !disableNavigation)
    )

    const pageErrorMessage = computed(() => {
      if (errorMessage.value) {
        return errorMessage.value
      }

      return facility.value ? '' : 'No information available'
    })

    // TODO: need to sanitize first
    const html = computed(() => facility.value?.html)
    const title = computed(() => facility.value?.name || '')
    const buttonText = computed(() => `Go to the ${title.value}`)
    const workingHours = computed(() =>
      getGroupedWorkingHours(facility.value?.working_hours)
    )
    const isTimetableVisible = computed(() =>
      Boolean(workingHours.value.length)
    )

    usePageTitle(title)

    const navigateToConfirmation = () =>
      router.push({
        name: ROUTE_NAME.NAVIGATION_CONFIRMATION,
        params: {
          [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_ID]: title.value,
          [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME]: title.value
        }
      })

    return {
      isLoading,
      facilities,
      pageErrorMessage,
      isTimetableVisible,
      workingHours,
      html,
      buttonText,
      title,
      navigateToConfirmation,
      signLanguage,
      isButtonVisible
    }
  }
})
