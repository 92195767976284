import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { setupAppVisitorId } from '@/services/visitor'
import { storage } from '@/helpers/browserStorage'

import focusDirective from '@/directives/focus'

const vueApp = createApp(App)

setupAppVisitorId(storage)

vueApp.use(router)
vueApp.use(store)

vueApp.directive('focus', focusDirective)

router.isReady().then(() => {
  vueApp.mount('#app')
})

export default vueApp
