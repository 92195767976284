import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9519feb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-confirmation align-center" }
const _hoisted_2 = {
  class: "navigation-confirmation__title",
  role: "alert",
  "aria-live": "assertive",
  "aria-atomic": "true"
}
const _hoisted_3 = { class: "navigation-confirmation__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_footer = _resolveComponent("a-footer")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("h3", _hoisted_2, [
      _createTextVNode(" Set your journey to " + _toDisplayString(_ctx.destinationName) + "? ", 1)
    ])), [
      [_directive_focus]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_button, {
        type: "white",
        outline: "",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("No")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        type: "white",
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode("Yes")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_footer)
  ]))
}