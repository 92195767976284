
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ADisplayTime',
  props: {
    scheduledTime: {
      type: String
    },
    actualTime: {
      type: String
    }
  },
  setup(props) {
    const hasBothTimes = computed(() =>
      Boolean(props.scheduledTime && props.actualTime)
    )

    const hasTimeDifference = computed(
      () => hasBothTimes.value && props.scheduledTime !== props.actualTime
    )

    const scheduledTimeClasses = computed(() => ({
      'display-time__cancelled': hasTimeDifference.value
    }))

    return {
      scheduledTimeClasses,
      hasTimeDifference
    }
  }
})
