
import { computed, defineComponent } from 'vue'

import ACardIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import ADisplayTime from '@/components/a-dispaly-time/display-time.vue'
import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'

export default defineComponent({
  name: 'ADepartureCardTFL',
  components: { ACardIcon, ADisplayTime },
  props: {
    scheduledTime: {
      type: String,
      required: true
    },
    actualTime: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: 'DLR'
    },
    platform: {
      type: String
    },
    disableNavigation: {
      type: Boolean
    }
  },
  setup(props) {
    const platformText = computed(() =>
      props.platform
        ? `from platform ${props.platform}`
        : '. No platform information available'
    )

    const accessibleText = computed(
      () =>
        `${props.subtitle} to ${props.title}. Departs at ${
          props.actualTime || props.scheduledTime
        } ${platformText.value}`
    )
    const redirectTo = computed(() => {
      if (props.platform && !props.disableNavigation) {
        return {
          name: ROUTE_NAME.NAVIGATION_CONFIRMATION,
          params: {
            [ROUTE_PARAMS.NAVIGATION_CONFIRMATION
              .DESTINATION_ID]: `Platform ${props.platform}`,
            [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME]: props.title
          }
        }
      } else {
        return {
          to: '#',
          disabled: true
        }
      }
    })

    return {
      ICON_TYPE,
      accessibleText,
      redirectTo
    }
  }
})
