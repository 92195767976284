import store from '@/store'
import { DisplayCallingPoint, Market } from '@gomedia-apis-ts-pis/v1/api'
import { ref } from 'vue'

import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { marketById } from '@/helpers/market-filter'

function isResponse(err): err is Response {
  return err instanceof Response
}

export const useJourney = (id: string) => {
  const isLoading = ref(true)
  const errorMessage = ref('')
  const callingPoints = ref<DisplayCallingPoint[]>([])
  const market: Market = marketById(id)

  const fetchJourney = async () => {
    try {
      const res = await store.dispatch('requestJourney', {
        market,
        id
      })
      if (!res.calling_points?.length) {
        throw new Error('No stations available')
      }
      callingPoints.value = res.calling_points
    } catch (e) {
      if (isResponse(e) && e.status === 404) {
        errorMessage.value = 'Journey not found'
      } else {
        errorMessage.value = e.message || 'An error has occurred'
      }
      analytics.sendEvent(AnalyticsEvents.REQUEST_ERROR, {
        errorMessage: errorMessage.value
      })
    } finally {
      isLoading.value = false
    }
  }

  fetchJourney()

  return {
    isLoading,
    errorMessage,
    callingPoints
  }
}
