import { Facility } from '@gomedia-apis-ts-pis/v1'

type ScheduleItem = { startDay: string; endDay: string; hours: string }
type Time = `${string}:${string}`

const dayOfTheWeek = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
}

enum facilityStatus {
  CLOSED = 'closed'
}

const sortedDays = Object.keys(dayOfTheWeek)

export const getGroupedWorkingHours = (
  workingHours: Facility['working_hours']
): string[] => {
  if (!workingHours) {
    return []
  }

  let scheduleIndex = 0
  const schedule: ScheduleItem[] = []
  for (let i = 0; i < sortedDays.length; i++) {
    const day = sortedDays[i]
    const prevDay = sortedDays[i - 1]
    const hours = workingHours[day]
    const prevDayHours = workingHours[prevDay]

    if (!hours) {
      continue
    }

    if (i !== 0 && hours !== prevDayHours && schedule[scheduleIndex]) {
      scheduleIndex++
    }
    if (schedule[scheduleIndex]) {
      schedule[scheduleIndex].endDay = day
    } else {
      schedule[scheduleIndex] = { startDay: day, hours, endDay: '' }
    }
  }

  return schedule.map((item) => {
    const { startDay, endDay, hours } = item
    if (!endDay) {
      return `${dayOfTheWeek[startDay]}, ${hours}`
    }
    return `${dayOfTheWeek[startDay]} to ${dayOfTheWeek[endDay]}, ${hours}`
  })
}

const convertTimeToDate = (time: string): Date | null => {
  if (!time) {
    return null
  }

  const [h, m] = time.split(':')
  const d = new Date()
  d.setHours(+h)
  d.setMinutes(+m)

  return d
}

const getNextDaysByDate = (date: Date) => {
  const currentDayIndex = date.getDay()

  const todayIndex = currentDayIndex === 0 ? 6 : currentDayIndex - 1
  const tomorrowIndex = (todayIndex + 1) % sortedDays.length

  return [sortedDays[todayIndex], sortedDays[tomorrowIndex]]
}

const convertToTimeType = (time: string): Time => {
  if (time && time.includes(':')) {
    return time.trim() as Time
  }

  return '00:00'
}

const getScheduleTime = (
  hours: string
): [Time, Time] | null | facilityStatus.CLOSED => {
  if (!hours) {
    return null
  } else if (hours === facilityStatus.CLOSED) {
    return facilityStatus.CLOSED
  }

  const [opensAt, closesAt] = hours.split('-')

  if (!opensAt || !closesAt) {
    return null
  }

  return [convertToTimeType(opensAt), convertToTimeType(closesAt)]
}

const getOpeningDetails = (hours: string): string => {
  const scheduleTime = getScheduleTime(hours)

  if (!scheduleTime) {
    return 'Closed'
  }

  const [opens] = scheduleTime
  return `Closed (opens at ${opens})`
}

const isEmptySchedule = (workingHours: Facility['working_hours']): boolean => {
  return !workingHours || sortedDays.every((day) => !workingHours[day])
}

export const getClosedMessage = (
  workingHours: Facility['working_hours']
): string => {
  if (!workingHours || isEmptySchedule(workingHours)) {
    return ''
  }

  // TODO: use proper time-zone
  const currentDate = new Date()
  const [today, tomorrow] = getNextDaysByDate(currentDate)

  const hours = workingHours[today]
  if (!hours) {
    return getOpeningDetails(workingHours[tomorrow])
  }

  const todaysSchedule = getScheduleTime(hours)
  if (!todaysSchedule) {
    return ''
  } else if (todaysSchedule === facilityStatus.CLOSED) {
    return 'Closed'
  }

  const [opensTodayAt, closesTodayAt] = todaysSchedule
  const opensAtTimeToCompare = convertTimeToDate(opensTodayAt)
  const closesAtTimeToCompare = convertTimeToDate(closesTodayAt)
  if (!opensAtTimeToCompare || !closesAtTimeToCompare) {
    return ''
  }

  if (closesAtTimeToCompare < opensAtTimeToCompare) {
    // if closesAtTime is smaller than opensAtTime time this means the facility closes tomorrow
    closesAtTimeToCompare.setDate(closesAtTimeToCompare.getDate() + 1)
  }

  if (currentDate < opensAtTimeToCompare) {
    return `Closed (opens at ${opensTodayAt})`
  }

  if (currentDate > closesAtTimeToCompare) {
    return getOpeningDetails(workingHours[tomorrow])
  }

  return ''
}
