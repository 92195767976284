import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "announcements" }
const _hoisted_2 = { class: "announcements__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_subheader = _resolveComponent("a-subheader")!
  const _component_announcement_item = _resolveComponent("announcement-item")!
  const _component_a_async_loader = _resolveComponent("a-async-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_subheader, {
      "show-video-btn": _ctx.signLanguage,
      title: _ctx.title
    }, null, 8, ["show-video-btn", "title"]),
    _createVNode(_component_a_async_loader, {
      "is-loading": _ctx.isLoading,
      "error-message": _ctx.errorMessage,
      class: "loader"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.announcements, (announcement, i) => {
            return (_openBlock(), _createBlock(_component_announcement_item, {
              key: i,
              announcement: announcement,
              "play-status": _ctx.activeIndex === i ? _ctx.nextStatus : 'PLAY',
              onConvertTextToSpeech: ($event: any) => (
            _ctx.convertTextToSpeech(_ctx.getAnnouncementText(announcement), i)
          )
            }, null, 8, ["announcement", "play-status", "onConvertTextToSpeech"]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["is-loading", "error-message"])
  ]))
}