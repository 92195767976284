import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-268ab5e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subheader__text" }
const _hoisted_2 = {
  key: 1,
  class: "subheader__video-btn-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_back_button = _resolveComponent("a-back-button")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.subheaderClasses)
  }, [
    (_ctx.showBackButton)
      ? (_openBlock(), _createBlock(_component_a_back_button, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1),
    (_ctx.showVideoBtn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "subheader__video-btn align-center",
            "aria-label": "British Sign Language",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
          }, [
            _createVNode(_component_a_icon, {
              "icon-type": _ctx.ICON_TYPE.VIDEOS_GUIDE
            }, null, 8, ["icon-type"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}