import * as UAParser from 'ua-parser-js'

export type BrowserDefaultParams = {
  deviceModel?: string
  deviceType?: string
  deviceVendor?: string
  browser?: string
  browserVersion?: string
  os?: string
  osVersion?: string
}

let BrowserData: any = null

export const browser = {
  setBrowserData() {
    try {
      BrowserData = new (UAParser as any)(navigator.userAgent).getResult()
    } catch (err) {
      console.log(err)
    }
  },

  getBrowserData() {
    if (!BrowserData) {
      this.setBrowserData()
    }
    return BrowserData
  },

  getBrowserDefaultParams(): BrowserDefaultParams {
    const browserData = this.getBrowserData()
    const addedValues: BrowserDefaultParams = {}

    if (browserData.browser.name !== undefined) {
      addedValues.browser = browserData.browser.name
    }

    if (browserData.browser.version !== undefined) {
      addedValues.browserVersion = browserData.browser.version
    }

    if (browserData.device.type !== undefined) {
      addedValues.deviceType = browserData.device.type
    }

    if (browserData.device.model !== undefined) {
      addedValues.deviceModel = browserData.device.model
    }

    if (browserData.device.vendor !== undefined) {
      addedValues.deviceVendor = browserData.device.vendor
    }

    if (browserData.os.name !== undefined) {
      addedValues.os = browserData.os.name
    }

    if (browserData.os.version !== undefined) {
      addedValues.osVersion = browserData.os.version
    }

    return addedValues
  }
}
