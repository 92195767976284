
import { computed, defineComponent } from 'vue'

import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'

import ADisplayTime from '@/components/a-dispaly-time/display-time.vue'
import AIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'

export default defineComponent({
  name: 'AJourneyItem',
  props: {
    title: {
      type: String,
      required: true
    },
    platform: {
      type: String,
      required: true
    },
    scheduledTime: {
      type: String,
      required: true
    },
    allowNavigation: {
      type: Boolean,
      default: true
    },
    actualTime: {
      type: String,
      required: true
    }
  },
  components: { AIcon, ADisplayTime },
  setup(props) {
    const linkTitle = computed(() => `Navigate to ${props.title}?`)

    const redirectTo = computed(() => {
      return {
        name: ROUTE_NAME.NAVIGATION_CONFIRMATION,
        params: {
          [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_ID]: props.platform,
          [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME]: props.title
        }
      }
    })

    const accessibleText = computed(
      () => `${props.actualTime || props.actualTime} ${props.title}`
    )

    const componentProps = computed(() =>
      props.allowNavigation
        ? {
            title: linkTitle.value
          }
        : {
            disabled: true
          }
    )

    return {
      ICON_TYPE,
      accessibleText,
      componentProps,
      redirectTo
    }
  }
})
