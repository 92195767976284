import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "departures" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_departure_list_header = _resolveComponent("a-departure-list-header")!
  const _component_a_subheader = _resolveComponent("a-subheader")!
  const _component_a_departure_list = _resolveComponent("a-departure-list")!
  const _component_a_async_loader = _resolveComponent("a-async-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_subheader, {
      title: _ctx.pageTitle,
      "show-video-btn": _ctx.signLanguage
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_departure_list_header)
      ]),
      _: 1
    }, 8, ["title", "show-video-btn"]),
    _createVNode(_component_a_async_loader, {
      "is-loading": _ctx.isLoading,
      "error-message": _ctx.errorMessage
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_departure_list, { departures: _ctx.departures }, null, 8, ["departures"])
      ]),
      _: 1
    }, 8, ["is-loading", "error-message"])
  ]))
}