import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9e7ae57c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "guide-wrapper"
}
const _hoisted_2 = { class: "guide__content" }
const _hoisted_3 = { class: "guide__info" }
const _hoisted_4 = { class: "guide__info--time" }
const _hoisted_5 = { class: "guide__close" }
const _hoisted_6 = { class: "guide__buttons" }
const _hoisted_7 = { class: "guide__buttons-wrapper" }
const _hoisted_8 = { class: "guide__buttons--count" }
const _hoisted_9 = { class: "guide__buttons--active-count" }
const _hoisted_10 = { class: "guide__buttons--next-count" }
const _hoisted_11 = { class: "guide__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!
  const _component_a_async_loader = _resolveComponent("a-async-loader")!

  return (_openBlock(), _createBlock(_component_a_async_loader, {
    "is-loading": _ctx.isLoading,
    "error-message": _ctx.pageErrorMessage,
    class: "guide"
  }, {
    default: _withCtx(() => [
      (_ctx.activeVideo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.activeVideo.title), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.activeVideo.subtitle), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args))),
                  class: "guide__close-btn",
                  title: "Close video"
                })
              ])
            ]),
            (_openBlock(), _createBlock(_component_video_player, {
              key: _ctx.videoPlayerKey,
              src: _ctx.activeVideo.link,
              onVideoPlayed: _ctx.onVideoPlayed
            }, null, 8, ["src", "onVideoPlayed"])),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args))),
                  "aria-label": "Previous video",
                  class: "guide__prev-btn"
                }, null, 512), [
                  [_vShow, !_ctx.hidePrevBtn]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.index + 1), 1),
                _createElementVNode("span", _hoisted_10, "/" + _toDisplayString(_ctx.totalVideoLength), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
                  "aria-label": "Next video",
                  class: "guide__next-btn"
                }, null, 512), [
                  [_vShow, !_ctx.hideNextBtn]
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-loading", "error-message"]))
}