import { FacilityType } from '@/enums/facilities'
import { VIDEO_TYPE } from '@/enums/videoType'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { getFormattedTimes } from '@/helpers/departures'
import store from '@/store'
import { DepartureVideo, Market, Facility } from '@gomedia-apis-ts-pis/v1'
import { ref } from 'vue'
import { StationAnnouncement } from '@gomedia-apis-ts-pis/v1/api'

enum DEPARTURE_VIDEO_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

const halfAnHourInMs = 1000 * 60 * 30

const VIDEO_TITLE_MAP = {
  [VIDEO_TYPE.DEPARTURE]: 'Next departures',
  [VIDEO_TYPE.ANNOUNCEMENT]: 'Station announcements',
  [VIDEO_TYPE.FACILITY]: 'Station facilities'
}

export type SignLanguageVideo = {
  link: string
  id: string
  title: string
  subtitle: string
  time?: string
}

export const DEFAULT_SRC = '/videos/Wait.mp4'

export const useVideos = (
  currentStationId: string,
  market: Market,
  videoType: VIDEO_TYPE,
  entityId: string
) => {
  const timeNow = new Date()
  const timeHalfAnHourAgo = new Date(Date.now() - halfAnHourInMs)
  const isLoading = ref(true)
  const errorMessage = ref('')

  function convertDepartures(
    departures: DepartureVideo[]
  ): SignLanguageVideo[] {
    return departures.map((video) => {
      const { scheduledTime, actualTime } = getFormattedTimes(
        // TODO: need to add time_zone field to response
        video.departure_times,
        'Europe/London'
        // video.display_time_zone
      )

      const link = (
        video.status === DEPARTURE_VIDEO_STATUS.COMPLETED
          ? video.link
          : DEFAULT_SRC
      ) as string

      return {
        link,
        id: video.request_id || '',
        title: VIDEO_TITLE_MAP[videoType],
        subtitle: `${actualTime || scheduledTime} Departure`,
        time: actualTime || scheduledTime
      }
    })
  }

  function convertFacilities(facilities: Facility[]): SignLanguageVideo[] {
    return facilities.reduce((arr, item) => {
      const link: string = item.video_link || DEFAULT_SRC
      if (!entityId) {
        arr.push({
          link,
          id: item.id || '',
          title: VIDEO_TITLE_MAP[videoType],
          subtitle: item.name || ''
        })

        return arr
      }

      if (item.id === entityId) {
        arr.push({
          link,
          id: item.id || '',
          title: VIDEO_TITLE_MAP[videoType],
          subtitle: item.name || ''
        })
      }

      return arr
    }, [] as SignLanguageVideo[])
  }

  function convertScheduledAnnouncements(
    scheduledAnnouncements: Array<StationAnnouncement>
  ): SignLanguageVideo[] {
    return scheduledAnnouncements.map((announcement) => {
      const link: string = announcement.video_link || DEFAULT_SRC

      return {
        link,
        id: '',
        title: VIDEO_TITLE_MAP[videoType],
        subtitle: `${announcement.time} Announcement`,
        time: announcement.time
      }
    })
  }

  const fetchDeparturesVideos = async (
    timeFrom?: Date,
    timeTo?: Date
  ): Promise<SignLanguageVideo[]> => {
    const res: Array<DepartureVideo> = await store.dispatch(
      'requestDeparturesVideos',
      {
        market,
        id: currentStationId,
        timeFrom,
        timeTo
      }
    )
    if (!res.length) {
      throw new Error('No videos found')
    }

    return convertDepartures(res)
  }

  const fetchScheduledAnnouncements = async (): Promise<
    SignLanguageVideo[]
  > => {
    const res: Array<StationAnnouncement> = await store.dispatch(
      'requestScheduledStationAnnouncements',
      {
        market,
        id: currentStationId
      }
    )
    if (!res.length) {
      throw new Error('No announcements found')
    }

    return convertScheduledAnnouncements(res)
  }

  const fetchFacilities = async (): Promise<SignLanguageVideo[]> => {
    const res = await store.dispatch('requestFacilities', {
      id: currentStationId,
      market,
      type: FacilityType.FACILITY
    })
    if (!res.length) {
      throw new Error('No facilities information available')
    }

    return convertFacilities(res)
  }

  function sortByTime(arr: SignLanguageVideo[]) {
    return arr.sort((a, b) => {
      const aParts = getNumericParts(a.time)
      const bParts = getNumericParts(b.time)

      // Sorts by hour then minute
      return bParts[0] - aParts[0] || bParts[1] - aParts[1]
    })

    function getNumericParts(time) {
      return time.split(':').map((x) => +x)
    }
  }

  const fetchAnnouncements = async (): Promise<SignLanguageVideo[]> => {
    const [departuresVideos, scheduledAnnouncements] = await Promise.all([
      fetchDeparturesVideos(timeHalfAnHourAgo, timeNow),
      fetchScheduledAnnouncements()
    ])
    const announcementsVideos = [...departuresVideos, ...scheduledAnnouncements]
    return sortByTime(announcementsVideos)
  }

  async function fetchVideos(): Promise<SignLanguageVideo[]> {
    const funcMap: {
      [key in VIDEO_TYPE]: (...args: any[]) => Promise<SignLanguageVideo[]>
    } = {
      [VIDEO_TYPE.DEPARTURE]: () => fetchDeparturesVideos(timeNow),
      [VIDEO_TYPE.FACILITY]: fetchFacilities,
      [VIDEO_TYPE.ANNOUNCEMENT]: fetchAnnouncements
    }

    try {
      return await funcMap[videoType]()
    } catch (e) {
      console.error(e)
      errorMessage.value = e.message || 'An error has occurred'
      analytics.sendEvent(AnalyticsEvents.REQUEST_ERROR, {
        errorMessage: errorMessage.value
      })
    } finally {
      isLoading.value = false
    }

    return []
  }

  return {
    isLoading,
    fetchVideos,
    errorMessage
  }
}
