
import { defineComponent } from 'vue'

import AFooter from '@/components/a-footer/footer.vue'

import { ROUTE_NAME } from '@/router'
import { useLayoutConfigProvider } from '@/hooks/useLayoutConfig'

export default defineComponent({
  name: 'AppLayout',
  components: {
    AFooter
  },
  setup() {
    useLayoutConfigProvider(ROUTE_NAME.JOURNEY, true, true, false)
    return {}
  }
})
