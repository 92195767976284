import { Market } from '@gomedia-apis-ts-pis/v1/api'
import { Facility } from '@gomedia-apis-ts-pis/v1'

import { ref } from 'vue'

import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { marketById } from '@/helpers/market-filter'
import { FacilityType } from '@/enums/facilities'
import store from '@/store'

export const useFacilities = (id: string, type = FacilityType.FACILITY) => {
  const isLoading = ref(true)
  const errorMessage = ref('')
  const facilities = ref<Facility[]>([])
  const market: Market = marketById(id)

  const fetchFacilities = async () => {
    try {
      const res = await store.dispatch('requestFacilities', {
        id,
        market,
        type
      })
      if (!res.length) {
        throw new Error('No facilities information available')
      }
      facilities.value = res
    } catch (e) {
      errorMessage.value = e.message || 'An error has occurred'
      analytics.sendEvent(AnalyticsEvents.REQUEST_ERROR, {
        errorMessage: errorMessage.value
      })
    } finally {
      isLoading.value = false
    }
  }

  fetchFacilities()

  return {
    isLoading,
    errorMessage,
    facilities
  }
}
