
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { defineComponent, ref } from 'vue'

import { usePageTitle } from '@/hooks/usePageTitle'
import { useDepartures } from '@/hooks/useDepartures'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useLayoutConfigInjection } from '@/hooks/useLayoutConfig'

import ADepartureList from '@/components/a-departure-list/departure-list.vue'
import ADepartureListHeader from '@/components/a-departure-list/departure-list-header.vue'
import ASubheader from '@/components/a-subheader/subheader.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'

export default defineComponent({
  name: 'Departures',
  components: {
    ASubheader,
    ADepartureList,
    ADepartureListHeader,
    AAsyncLoader,
    AStationHeader
  },
  setup() {
    const pageTitle = ref('All departures')
    usePageTitle(pageTitle)

    const { signLanguage } = useStationConfigInjection()
    const { currentStationId } = useStationIdInjection()
    const { showBackButton } = useLayoutConfigInjection()

    const { isLoading, departures, errorMessage } =
      useDepartures(currentStationId)

    return {
      pageTitle,
      isLoading,
      errorMessage,
      departures,
      signLanguage,
      showBackButton
    }
  }
})
