import { Market } from '@gomedia-apis-ts-pis/v1/api'

const TFLIds = ['940GZZDLCAN', '940GZZDLCUT', '940GZZDLTWG', '940GZZDLWLA']

export const marketById = (stationId: string) => {
  if (TFLIds.includes(stationId)) {
    return Market.TFL
  } else {
    return Market.UK
  }
}
