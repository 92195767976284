import {
  Configuration,
  GetDeparturesResponse,
  GetScheduledAnnouncementsResponse,
  Market
} from '@gomedia-apis-ts-pis/v1'
import {
  ApiServiceApi,
  Journey,
  GetStationFacilitiesResponse,
  GetDeparturesVideosApiResponse,
  GetStationAnnouncementsResponse
} from '@gomedia-apis-ts-pis/v1/api'
import { getDomain } from '@/helpers/domain'

export type RequestParams = {
  market: Market
  id: string
}

export type VideoRequestParams = RequestParams & {
  timeFrom: Date
  timeTo: Date
}

export type RequestWithTypeParams = RequestParams & { type: string }

export class APIService {
  private api: ApiServiceApi

  constructor() {
    const domain = getDomain()
    const config: Configuration = new Configuration({
      basePath: domain
    })

    this.api = new ApiServiceApi(config)
  }

  public async getAllDepartures(
    params: RequestParams
  ): Promise<GetDeparturesResponse> {
    return this.api.getDepartures(params.market, params.id)
  }

  public async getJourney(params: RequestParams): Promise<Journey> {
    return this.api.getJourney(params.market, params.id)
  }

  public async getFacilities(
    params: RequestWithTypeParams
  ): Promise<GetStationFacilitiesResponse> {
    return this.api.getStationFacilities(params.id, params.market, params.type)
  }

  public async getDeparturesVideos(
    params: VideoRequestParams
  ): Promise<GetDeparturesVideosApiResponse> {
    return this.api.getDeparturesVideos(
      params.market,
      params.id,
      params.timeFrom,
      params.timeTo
    )
  }

  public async getStationAnnouncements(
    params: RequestParams
  ): Promise<GetStationAnnouncementsResponse> {
    return this.api.getStationAnnouncements(params.market, params.id)
  }

  public async getScheduledStationAnnouncements(
    params: RequestParams
  ): Promise<GetScheduledAnnouncementsResponse> {
    return this.api.getScheduledAnnouncements(params.market, params.id)
  }
}
