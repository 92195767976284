import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1ba1bac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subheader-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_subheader_bar = _resolveComponent("subheader-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_subheader_bar, {
      title: _ctx.title,
      "show-video-btn": _ctx.showVideoBtn,
      "show-back-button": _ctx.showBackButton && !_ctx.hideBackButton
    }, null, 8, ["title", "show-video-btn", "show-back-button"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}