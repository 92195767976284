import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "journey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_subheader = _resolveComponent("a-subheader")!
  const _component_a_journey_list = _resolveComponent("a-journey-list")!
  const _component_a_async_loader = _resolveComponent("a-async-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_a_subheader, {
          key: 0,
          title: _ctx.title
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_async_loader, {
      "is-loading": _ctx.isLoading,
      "error-message": _ctx.errorMessage
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_journey_list, {
          "calling-points": _ctx.callingPoints,
          platform: _ctx.platform
        }, null, 8, ["calling-points", "platform"])
      ]),
      _: 1
    }, 8, ["is-loading", "error-message"])
  ]))
}