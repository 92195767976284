
import { defineComponent } from 'vue'

import AFooter from '@/components/a-footer/footer.vue'

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    AFooter
  }
})
