
import { computed, defineComponent } from 'vue'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useStationConfigInjection } from '@/hooks/useStationConfig'

export default defineComponent({
  name: 'AStationHeader',
  setup() {
    const { currentStationId } = useStationIdInjection()
    const { station_names } = useStationConfigInjection()

    const stationName = computed(
      (): string => station_names?.[currentStationId] as string
    )

    return {
      stationName
    }
  }
})
