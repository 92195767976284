
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DisplayCallingPoint } from '@gomedia-apis-ts-pis/v1'

import { usePageTitle } from '@/hooks/usePageTitle'
import { useJourney } from '@/hooks/useJourney'
import { getDestinationName } from '@/helpers/departures'
import { ROUTE_PARAMS } from '@/router'

import ASubheader from '@/components/a-subheader/subheader.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import AJourneyList from '@/components/a-journey-list/journey-list.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'

export default defineComponent({
  name: 'Journey',
  components: { ASubheader, AAsyncLoader, AJourneyList, AStationHeader },
  props: {
    stationId: {
      type: String,
      required: true
    },
    time: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute()
    const { callingPoints, isLoading, errorMessage } = useJourney(
      props.stationId
    )

    const title = computed(() => {
      const destinationName = getDestinationName(
        callingPoints.value as DisplayCallingPoint[]
      )
      if (destinationName && props.time) {
        return `Calling stations for the ${props.time} to ${destinationName}`
      }
      if (destinationName) {
        return `Calling stations to ${destinationName}`
      }
      if (props.time) {
        return `Calling stations for the ${props.time}`
      }
      return 'Calling stations'
    })

    const platform = ref(route.params[ROUTE_PARAMS.JOURNEY.PLATFORM])

    usePageTitle(title)

    return {
      title,
      callingPoints,
      isLoading,
      platform,
      errorMessage
    }
  }
})
