
import { defineComponent, PropType } from 'vue'
import { useRouter, RouteLocationRaw } from 'vue-router'

import { openExternalURL } from '@/helpers/navilens-bridge'
import { useStationConfigInjection } from '@/hooks/useStationConfig'

export default defineComponent({
  name: 'ALink',
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      required: true
    }
  },
  setup(props) {
    const { openInNavilens } = useStationConfigInjection()
    const router = useRouter()

    const redirect = () => {
      if (typeof props.to === 'string' && props.to.startsWith('http')) {
        openInNavilens
          ? openExternalURL(props.to)
          : window.open(props.to, '_blank')
        return
      }

      router.push(props.to)
    }

    return {
      redirect
    }
  }
})
