import { provide, inject, InjectionKey } from 'vue'
import { Market } from '@gomedia-apis-ts-pis/v1/api'
import { marketById } from '@/helpers/market-filter'

const key: InjectionKey<string> = Symbol('stationId')

export const useStationIdProvider = (currentStationId: string) => {
  provide(key, currentStationId)
}

export const useStationIdInjection = () => {
  const currentStationId = inject(key) || ''
  const market: Market = marketById(currentStationId)

  return {
    currentStationId,
    market
  }
}
