
import { defineComponent, computed, PropType } from 'vue'
import { useRouter, RouteLocationRaw } from 'vue-router'

import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import AIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import ANavigationButton from '@/components/a-navigation-button/navigation-button.vue'
import ALink from '@/components/a-link/link.vue'

export type LinkCard = {
  to: RouteLocationRaw
  title: string
  iconType: ICON_TYPE
  destinationId?: string
  inactive?: boolean
  warningMessage?: string
  titleTextToRead?: string
  showNavigateButton: boolean
}

export default defineComponent({
  name: 'ALinkCard',
  components: { AIcon, ALink, ANavigationButton },
  props: {
    to: {
      type: [String, Object] as PropType<LinkCard['to']>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    destinationId: {
      type: String
    },
    warningMessage: {
      type: String
    },
    titleTextToRead: {
      type: String
    },
    iconType: {
      type: String as PropType<LinkCard['iconType']>
    },
    inactive: {
      type: Boolean,
      default: false
    },
    navigationIconType: {
      type: String
    },
    showNavigateButton: {
      type: Boolean
    }
  },
  setup(props) {
    const router = useRouter()
    const cardClasses = computed(() => ({
      'link-card': true,
      'link-card_inactive': props.inactive
    }))

    const linkClasses = computed(() => ({
      'link-card__link': true,
      'link-card__link_no-icon': !props.iconType
    }))

    const titleText = computed(() => props.titleTextToRead || props.title)

    const accessibleText = computed(() =>
      props.warningMessage
        ? `${titleText.value}; ${props.warningMessage}`
        : titleText.value
    )

    function sendAnalytics() {
      analytics.sendEvent(AnalyticsEvents.NAVIGATION_ITEM_CLICKED, {
        link:
          typeof props.to === 'string'
            ? props.to
            : router.resolve(props.to).href
      })
    }

    return {
      ICON_TYPE,
      cardClasses,
      linkClasses,
      accessibleText,
      sendAnalytics
    }
  }
})
