import { Environment } from '@/enums/environment'
import { BrowserDefaultParams, browser } from '@/helpers/browser'
import { storage } from '@/helpers/browserStorage'
import { LS_NAME } from '@/enums/localStorage'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { getEnvironment } from '@/helpers/environment'
import router, { ROUTE_PARAMS } from '@/router'

export type DefaultAnalyticParams = BrowserDefaultParams & {
  pageId: string
  visitor_id: string | undefined
}

export const analytics = {
  getDefParams(): DefaultAnalyticParams {
    const stationId =
      router.currentRoute.value.params[ROUTE_PARAMS.CURRENT_STATION_ID]

    const newObj = {
      ...browser.getBrowserDefaultParams(),
      station_id: stationId,
      pageId: location.pathname,
      visitor_id: storage.read(LS_NAME.VISITOR_ID_LS_NAME)
    }

    Object.keys(newObj).forEach((key) => {
      if (key.length > 0) {
        const temp = newObj[key]
        const val = typeof temp !== 'function' ? temp : temp()
        if (typeof val !== 'object') {
          newObj[key] = val
        }
      }
    })

    return newObj
  },
  getAnalyticsURL() {
    const env = getEnvironment()

    const urlMap = {
      [Environment.LOCAL]: 'https://golytics.dev.gomedia.io',
      [Environment.DEV]: 'https://golytics.dev.gomedia.io',
      [Environment.STG]: 'https://golytics.stg.gomedia.io',
      [Environment.PRD]: ''
    }

    return urlMap[env]
  },

  async sendEvent(name: AnalyticsEvents, data: Record<string, unknown> = {}) {
    const defParams = this.getDefParams()
    const params: Record<string, unknown> = Object.assign(defParams, data)
    try {
      const url = analytics.getAnalyticsURL()

      if (!url) {
        return
      }

      return fetch(`${url}/api/v1/analytics/${name}`, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          'x-client-id': 'visor',
          'x-product': 'visor'
        },
        method: 'POST'
      })
    } catch (e) {
      console.error(e)
    }
  }
}
