
import { computed, defineComponent, PropType } from 'vue'

import ACardIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import ADisplayTime from '@/components/a-dispaly-time/display-time.vue'

import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { useRouter, RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'ADepartureCard',
  components: { ACardIcon, ADisplayTime },
  props: {
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true
    },
    scheduledTime: {
      type: String,
      required: true
    },
    actualTime: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: 'Train'
    },
    platform: {
      type: String
    },
    cancelled: {
      type: Boolean
    }
  },
  setup(props) {
    const router = useRouter()
    const platformText = computed(() =>
      props.platform
        ? `from platform ${props.platform}`
        : '. No platform information available'
    )

    const accessibleText = computed(
      () =>
        `${props.subtitle} to ${props.title}. Departs at ${
          props.actualTime || props.scheduledTime
        } ${platformText.value}`
    )

    function sendAnalytics() {
      analytics.sendEvent(AnalyticsEvents.NAVIGATION_ITEM_CLICKED, {
        link: router.resolve(props.to).href
      })
    }

    function handleClick() {
      sendAnalytics()
      router.push(props.to)
    }

    return {
      ICON_TYPE,
      accessibleText,
      handleClick
    }
  }
})
