
import { defineComponent, ref, toRefs } from 'vue'
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { usePageTitle } from '@/hooks/usePageTitle'
import { useVoiceSpeech } from '@/hooks/useVoiceSpeech'
import store from '@/store'
import AnnouncementItem from '@/components/a-announcement-item/announcement-item.vue'
import ASubheader from '@/components/a-subheader/subheader.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'
import { useStationIdInjection } from '@/hooks/useStationId'
import { StationAnnouncement } from '@gomedia-apis-ts-pis/v1/api'
import { getNameI18n } from '@/helpers/departures'

export default defineComponent({
  name: 'StationAnnouncements',
  components: {
    AnnouncementItem,
    AAsyncLoader,
    ASubheader,
    AStationHeader
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const announcements = ref<Array<StationAnnouncement>>([])
    const { title } = toRefs(props)
    const isLoading = ref<boolean>(true)
    const errorMessage = ref<string>()
    const { signLanguage } = useStationConfigInjection()
    const { market, currentStationId } = useStationIdInjection()

    const { convertTextToSpeech, activeIndex, nextStatus } = useVoiceSpeech()

    usePageTitle(title)

    function getAnnouncementText(announcement: StationAnnouncement) {
      return `${announcement.time} '.' ${getNameI18n(announcement.message)}`
    }

    async function fetchStationAnnouncements() {
      try {
        return await store.dispatch('requestStationAnnouncements', {
          market,
          id: currentStationId
        })
      } catch (e) {
        console.error(e)
        errorMessage.value = e.message || 'An error has occurred'
      } finally {
        isLoading.value = false
      }
    }

    async function init() {
      announcements.value = await fetchStationAnnouncements()
    }

    init()

    return {
      announcements,
      signLanguage,
      convertTextToSpeech,
      activeIndex,
      nextStatus,
      getAnnouncementText,
      isLoading,
      errorMessage
    }
  }
})
