import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d5ffca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visually-hidden visually-hidden_card" }
const _hoisted_2 = { class: "link-card__title" }
const _hoisted_3 = {
  key: 0,
  class: "link-card__warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_a_link = _resolveComponent("a-link")!
  const _component_a_navigation_button = _resolveComponent("a-navigation-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cardClasses)
  }, [
    _createVNode(_component_a_link, {
      class: "link-card__wrapper",
      to: _ctx.to,
      onClickCapture: _ctx.sendAnalytics
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.accessibleText), 1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.linkClasses),
          "aria-hidden": "true"
        }, [
          (_ctx.iconType)
            ? (_openBlock(), _createBlock(_component_a_icon, {
                key: 0,
                "icon-type": _ctx.iconType
              }, null, 8, ["icon-type"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
            (_ctx.warningMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_a_icon, {
                    "icon-type": _ctx.ICON_TYPE.WARNING
                  }, null, 8, ["icon-type"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.warningMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["to", "onClickCapture"]),
    (_ctx.showNavigateButton)
      ? (_openBlock(), _createBlock(_component_a_navigation_button, {
          key: 0,
          title: _ctx.title,
          "icon-type": _ctx.navigationIconType,
          "destination-id": _ctx.destinationId
        }, null, 8, ["title", "icon-type", "destination-id"]))
      : _createCommentVNode("", true)
  ], 2))
}