import { ROUTE_NAME } from '@/enums/routeNames'
import { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import { mergeDeepRight } from '@/helpers/mergeDeepRight'
import { useStationIdInjection } from '@/hooks/useStationId'
import { Market } from '@gomedia-apis-ts-pis/v1/api'

type MenuItem = {
  to: { name: ROUTE_NAME } | string
  title: string
  iconType: ICON_TYPE
  warningMessage?: string
}

type StationConfig = {
  menuItems: MenuItem[]
  station_names?: { [key: string]: string }
  allowDepartureNavigation: boolean
  disableNavigation: boolean
  signLanguage: boolean
  showStationHeader: boolean
  openInNavilens: boolean
  redirectToFacilitiesPage?: boolean
}

type ConfigObject = {
  [key in Market]?: {
    config: StationConfig
    stationsConfig?: Array<{
      stations: string[]
      config: Partial<StationConfig>
    }>
  }
}

const config: ConfigObject = {
  [Market.TFL]: {
    config: {
      menuItems: [
        {
          to: { name: ROUTE_NAME.DEPARTURES },
          title: 'All departures',
          iconType: ICON_TYPE.DEPARTURES
        },
        {
          to: { name: ROUTE_NAME.STATION_FACILITIES },
          title: 'Station facilities',
          iconType: ICON_TYPE.FACILITIES
        }
      ],
      allowDepartureNavigation: true,
      disableNavigation: false,
      signLanguage: false,
      showStationHeader: false,
      openInNavilens: true
    }
  },
  [Market.UK]: {
    config: {
      menuItems: [
        {
          to: { name: ROUTE_NAME.DEPARTURES },
          title: 'All departures',
          iconType: ICON_TYPE.DEPARTURES
        },
        {
          to: { name: ROUTE_NAME.STATION_FACILITIES },
          title: 'Station facilities',
          iconType: ICON_TYPE.FACILITIES
        },
        {
          to: { name: ROUTE_NAME.SHOPS },
          title: 'Food, drinks & shops',
          iconType: ICON_TYPE.RESTAURANTS
        }
      ],
      allowDepartureNavigation: false,
      disableNavigation: false,
      signLanguage: false,
      showStationHeader: false,
      openInNavilens: true
    },
    stationsConfig: [
      {
        // LONDON_OVERGROUND_STATIONS
        stations: [
          'HACKNYC',
          'HAKNYNM',
          'HOXTON',
          'UPRHLWY',
          'WLSD',
          'WLSDJHL'
        ],
        config: {
          menuItems: [
            {
              to: { name: ROUTE_NAME.DEPARTURES },
              title: 'All departures',
              iconType: ICON_TYPE.DEPARTURES
            },
            {
              to: { name: ROUTE_NAME.STATION_FACILITIES },
              title: 'Station facilities',
              iconType: ICON_TYPE.FACILITIES
            },
            {
              to: { name: ROUTE_NAME.STATION_ANNOUNCEMENTS },
              title: 'Station Announcements',
              iconType: ICON_TYPE.ANNOUNCEMENTS
            },
            {
              to: 'https://forms.office.com/r/wp3ZjxEcDV',
              title: 'Survey',
              iconType: ICON_TYPE.SURVEY
            }
          ],
          station_names: {
            HACKNYC: 'Hackney Central',
            HAKNYNM: 'Hackney Downs',
            HOXTON: 'Hoxton',
            WLSD: 'Willesden Junction',
            UPRHLWY: 'Upper Holloway',
            WLSDJHL: 'Willesden Junction'
          },
          disableNavigation: true,
          signLanguage: true,
          showStationHeader: true,
          openInNavilens: false
        }
      },
      {
        stations: ['GRIMALDI'],
        config: {
          menuItems: [
            {
              to: { name: ROUTE_NAME.STATION_FACILITIES },
              title: 'Facilities',
              iconType: ICON_TYPE.FACILITIES
            }
          ],
          redirectToFacilitiesPage: true
        }
      }
    ]
  }
}

export function getStationConfig(
  market: Market,
  stationID: string
): StationConfig {
  const configByMarket = config[market]
  if (!configByMarket) {
    throw new Error('No config provided for this station')
  }
  const defaultConfig = configByMarket.config
  const stationConfig = configByMarket.stationsConfig?.find((cfg) =>
    cfg.stations.includes(stationID)
  )

  if (!stationConfig) {
    return defaultConfig
  }

  return mergeDeepRight(defaultConfig, stationConfig.config)
}

export const useStationConfigInjection = (): StationConfig => {
  const { market, currentStationId } = useStationIdInjection()

  return getStationConfig(market, currentStationId)
}
