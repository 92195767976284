
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { computed, defineComponent } from 'vue'

import { useDepartures } from '@/hooks/useDepartures'

import ADepartureList from '@/components/a-departure-list/departure-list.vue'
import ADepartureListHeader from '@/components/a-departure-list/departure-list-header.vue'
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import ASubheaderBar from '@/components/a-subheader/subheader-bar.vue'

const MAX_VISIBLE_ELEMENTS = 3

export default defineComponent({
  name: 'ADeparturesWidget',
  components: {
    ADepartureList,
    ADepartureListHeader,
    AAsyncLoader,
    ASubheaderBar
  },
  props: {
    stationId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { signLanguage } = useStationConfigInjection()

    const { isLoading, departures, errorMessage } = useDepartures(
      props.stationId
    )

    const nextDepartures = computed(() =>
      departures.value.slice(0, MAX_VISIBLE_ELEMENTS)
    )

    return {
      isLoading,
      errorMessage,
      signLanguage,
      nextDepartures
    }
  }
})
