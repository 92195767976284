import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4d915d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-busy"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "aria-busy": _ctx.isLoading,
    class: _normalizeClass(_ctx.containerClass)
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Loading..."))
      : (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
  ], 10, _hoisted_1))
}