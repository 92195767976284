import { BrowserStorage } from '@/helpers/browserStorage'
import { uuid } from '@/helpers/uuid'
import { LS_NAME } from '@/enums/localStorage'

function putVisitorIdToStorage(storage: BrowserStorage, id: string) {
  storage.save(LS_NAME.VISITOR_ID_LS_NAME, id)
}

export function setupAppVisitorId(storage: BrowserStorage) {
  const id = storage.read(LS_NAME.VISITOR_ID_LS_NAME)
  if (id) {
    return id
  }
  const generatedId = uuid.generate()
  putVisitorIdToStorage(storage, generatedId)
  return generatedId
}
