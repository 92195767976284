import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-500a33c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-time" }
const _hoisted_2 = ["datetime"]
const _hoisted_3 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("time", {
      class: _normalizeClass(_ctx.scheduledTimeClasses),
      datetime: _ctx.scheduledTime
    }, _toDisplayString(_ctx.scheduledTime), 11, _hoisted_2),
    (_ctx.hasTimeDifference)
      ? (_openBlock(), _createElementBlock("time", {
          key: 0,
          class: "display-time__actual",
          datetime: _ctx.actualTime
        }, _toDisplayString(_ctx.actualTime), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}