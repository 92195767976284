
import { computed, defineComponent, PropType } from 'vue'
import { TrainDeparture } from '@gomedia-apis-ts-pis/v1/api'
import { RouteLocationRaw } from 'vue-router'

import { useLayoutConfigInjection } from '@/hooks/useLayoutConfig'
import { useStationConfigInjection } from '@/hooks/useStationConfig'

import { ROUTE_PARAMS } from '@/router'
import { getFormattedTimes, getNameI18n } from '@/helpers/departures'

import ADepartureCard from '@/components/a-departure-card/departure-card.vue'
import ADepartureCardNavigation from '@/components/a-departure-card-navigation/a-departure-card-navigation.vue'

type DepartureCardItem = {
  title: string
  navigateTo: RouteLocationRaw
  subtitle?: string
  platform?: string
  scheduledTime: string
  actualTime?: string
  cancelled: boolean
}

export default defineComponent({
  name: 'ADepartureList',
  components: { ADepartureCard, ADepartureCardNavigation },
  props: {
    departures: {
      type: Array as PropType<TrainDeparture[]>,
      required: true
    }
  },
  setup(props) {
    const { journeyRouteName, isWebVersion } = useLayoutConfigInjection()
    const { allowDepartureNavigation } = useStationConfigInjection()
    const departureList = computed<DepartureCardItem[]>(() =>
      props.departures.map((item) => {
        const { scheduledTime, actualTime } = getFormattedTimes(
          item.departure_times,
          item.display_time_zone
        )
        return {
          navigateTo: {
            name: journeyRouteName,
            params: {
              [ROUTE_PARAMS.JOURNEY.STATION_ID]: item.journey_id?.id || '',
              [ROUTE_PARAMS.JOURNEY.PLATFORM]: item.platform || '-',
              [ROUTE_PARAMS.JOURNEY.TIME]: scheduledTime
            }
          },
          scheduledTime: scheduledTime,
          actualTime: actualTime,
          title: getNameI18n(item.destination_name),
          platform: item.platform,
          subtitle: item.transport_company_name,
          cancelled: item.cancelled || false
        }
      })
    )

    return {
      allowDepartureNavigation,
      departureList,
      isWebVersion
    }
  }
})
