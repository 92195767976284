
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

import { ROUTE_PARAMS } from '@/router'
import { useStationIdProvider } from '@/hooks/useStationId'

export default defineComponent({
  name: 'App',
  setup() {
    const route = useRoute()
    const id = route.params[ROUTE_PARAMS.CURRENT_STATION_ID] as string
    useStationIdProvider(id?.toUpperCase())

    return {}
  }
})
