
import { computed, defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import ABackButton from '@/components/a-back-button/back-button.vue'
import AIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'
import { useVideosNavigation } from '@/hooks/useVideosNavigation'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'

export default defineComponent({
  name: 'ASubheaderBar',
  components: { ABackButton, AIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    showVideoBtn: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const subheaderClasses = computed(() => ({
      subheader: true,
      'subheader_with-button': props.showBackButton
    }))

    const { navigateTo } = useVideosNavigation(route)

    function sendAnalytics() {
      analytics.sendEvent(AnalyticsEvents.SIGN_LANGUAGE_OPENED, {
        link: router.resolve(navigateTo.value).href,
        type: (navigateTo.value.params.type as string)?.toUpperCase()
      })
    }

    function handleClick() {
      sendAnalytics()
      router.push(navigateTo.value)
    }

    return {
      subheaderClasses,
      ICON_TYPE,
      navigateTo,
      handleClick
    }
  }
})
