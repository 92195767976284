import { provide, inject, InjectionKey } from 'vue'

import { ROUTE_NAME } from '@/router'

const JOURNEY_ROUTE_NAME: InjectionKey<string> = Symbol('journeyRouteName')
const SHOW_BACK_BUTTON: InjectionKey<boolean> = Symbol('showBackButton')
const ALLOW_JOURNEY_NAVIGATION: InjectionKey<boolean> = Symbol(
  'allowJourneyNavigation'
)
const IS_WEB_VERSION: InjectionKey<boolean> = Symbol('isWebVersion')

export const useLayoutConfigProvider = (
  journeyRouteName: ROUTE_NAME,
  showBackButton: boolean,
  allowJourneyNavigation: boolean,
  isWebVersion: boolean
) => {
  provide(JOURNEY_ROUTE_NAME, journeyRouteName)
  provide(SHOW_BACK_BUTTON, showBackButton)
  provide(ALLOW_JOURNEY_NAVIGATION, allowJourneyNavigation)
  provide(IS_WEB_VERSION, isWebVersion)
}

export const useLayoutConfigInjection = () => {
  const journeyRouteName = inject(JOURNEY_ROUTE_NAME, '')
  const showBackButton = inject(SHOW_BACK_BUTTON, true)
  const allowJourneyNavigation = inject(ALLOW_JOURNEY_NAVIGATION, true)
  const isWebVersion = inject(IS_WEB_VERSION, false)

  return {
    journeyRouteName,
    showBackButton,
    allowJourneyNavigation,
    isWebVersion
  }
}
