import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "navigate-btn align-center",
    title: _ctx.buttonTitle,
    to: _ctx.navigateTo,
    tag: "button"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_icon, { "icon-type": _ctx.iconType }, null, 8, ["icon-type"])
    ]),
    _: 1
  }, 8, ["title", "to"]))
}