class GoMediaUuid {
  public generate(): string {
    return (
      this.chunk('s4') +
      this.chunk('s4') +
      '-' +
      this.chunk('s4') +
      '-' +
      this.chunk('s4') +
      '-' +
      this.chunk('s4') +
      '-' +
      this.chunk('s4') +
      this.chunk('s4') +
      this.chunk('s4')
    )
  }

  private chunk(type): string {
    let block: any

    if (type === 's4') {
      block = this.s4()
    }
    return block
  }

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
}

export const uuid = new GoMediaUuid()
