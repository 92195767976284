
import { computed, defineComponent } from 'vue'

import { ROUTE_NAME, ROUTE_PARAMS } from '@/router'

import AIcon, { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'

export default defineComponent({
  name: 'ANavigationButton',
  components: { AIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    destinationId: {
      type: String,
      required: true
    },
    iconType: {
      type: String,
      default: ICON_TYPE.NAVIGATE_BLUE
    }
  },
  setup(props) {
    const buttonTitle = computed(() => `Navigate to ${props.title}`)
    const navigateTo = computed(() => ({
      name: ROUTE_NAME.NAVIGATION_CONFIRMATION,
      params: {
        [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_ID]:
          props.destinationId,
        [ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME]: props.title
      }
    }))

    return {
      navigateTo,
      buttonTitle
    }
  }
})
