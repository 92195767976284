import router, { ROUTE_NAME } from '@/router'
import store from '@/store'

export const navigateBack = () => {
  if (store.state.routeHistory.length <= 2) {
    store.dispatch('clearRouteHistory')
    router.push({ name: ROUTE_NAME.HOME }).catch((e) => {
      // Handling an expected error when navigating back to
      // a redirected page specified in navigation guards
      console.debug(e)
    })
  } else {
    store.dispatch('updateRouteHistory')
    router.go(-1)
  }
}
