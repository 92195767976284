import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_station_header = _resolveComponent("a-station-header")!

  return (_openBlock(), _createElementBlock("header", null, [
    (_ctx.showStationHeader)
      ? (_openBlock(), _createBlock(_component_a_station_header, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}