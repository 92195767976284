import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "departure-list"
}
const _hoisted_2 = {
  key: 1,
  class: "departure-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_departure_card_navigation = _resolveComponent("a-departure-card-navigation")!
  const _component_a_departure_card = _resolveComponent("a-departure-card")!

  return (_ctx.allowDepartureNavigation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departureList, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_departure_card_navigation, {
            key: `${item.platform}-${index}`,
            "scheduled-time": item.scheduledTime,
            "actual-time": item.actualTime,
            title: item.title,
            subtitle: item.subtitle,
            platform: item.platform,
            disableNavigation: _ctx.isWebVersion
          }, null, 8, ["scheduled-time", "actual-time", "title", "subtitle", "platform", "disableNavigation"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departureList, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_departure_card, {
            key: `${item.platform}-${index}`,
            "scheduled-time": item.scheduledTime,
            "actual-time": item.actualTime,
            title: item.title,
            subtitle: item.subtitle,
            platform: item.platform,
            cancelled: item.cancelled,
            to: item.navigateTo
          }, null, 8, ["scheduled-time", "actual-time", "title", "subtitle", "platform", "cancelled", "to"]))
        }), 128))
      ]))
}