import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-21b896d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "visually-hidden visually-hidden_card",
  role: "button"
}
const _hoisted_2 = {
  class: "departure-card__main",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "departure-card__text" }
const _hoisted_4 = { class: "departure-card__title" }
const _hoisted_5 = { class: "departure-card__info" }
const _hoisted_6 = { class: "departure-card__signs" }
const _hoisted_7 = { class: "departure-card__platform align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_display_time = _resolveComponent("a-display-time")!
  const _component_a_card_icon = _resolveComponent("a-card-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.redirectTo,
    disabled: _ctx.disableNavigation,
    class: "departure-card",
    tag: "button"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.accessibleText), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_display_time, {
          class: "departure-card__time",
          "actual-time": _ctx.actualTime,
          "scheduled-time": _ctx.scheduledTime
        }, null, 8, ["actual-time", "scheduled-time"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.subtitle), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.platform || '--'), 1)
          ]),
          _createVNode(_component_a_card_icon, {
            "icon-type": _ctx.ICON_TYPE.NEXT
          }, null, 8, ["icon-type"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to", "disabled"]))
}