
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    outline: {
      type: Boolean
    }
  }
})
