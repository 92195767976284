import { VIDEO_TYPE } from '@/enums/videoType'
import { computed } from 'vue'
import { ROUTE_NAME } from '@/enums/routeNames'
import { ROUTE_PARAMS } from '@/router'
import { RouteLocationNormalizedGeneric } from 'vue-router'

export const useVideosNavigation = (route: RouteLocationNormalizedGeneric) => {
  const typeMap = {
    [ROUTE_NAME.HOME]: VIDEO_TYPE.DEPARTURE,
    [ROUTE_NAME.DEPARTURES]: VIDEO_TYPE.DEPARTURE,
    [ROUTE_NAME.INFO_PAGE]: VIDEO_TYPE.FACILITY,
    [ROUTE_NAME.STATION_ANNOUNCEMENTS]: VIDEO_TYPE.ANNOUNCEMENT
  }

  const idMap = {
    [ROUTE_NAME.INFO_PAGE]: route.params[ROUTE_PARAMS.INFO_PAGE.PAGE_ID]
  }

  const navigateTo = computed(() => {
    const routeName = route.name || ''

    return {
      name: ROUTE_NAME.VIDEO_GUIDE,
      params: {
        [ROUTE_PARAMS.VIDEO_GUIDE.TYPE]: typeMap[routeName],
        [ROUTE_PARAMS.VIDEO_GUIDE.ID]: idMap[routeName]
      }
    }
  })

  return {
    navigateTo
  }
}
