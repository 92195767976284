
import { defineComponent, reactive, ref } from 'vue'

import { ROUTE_NAME } from '@/router'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useStationConfigInjection } from '@/hooks/useStationConfig'
import { usePageTitle } from '@/hooks/usePageTitle'

import ALinkCard from '@/components/a-link-card/link-card.vue'
import ADeparturesWidget from '@/components/a-departures-widget/departures-widget.vue'
import AStationHeader from '@/components/a-station-header/station-header.vue'
import { ICON_TYPE } from '@/components/a-card-icon/card-icon.vue'

export default defineComponent({
  name: 'Home',
  components: { ALinkCard, ADeparturesWidget, AStationHeader },
  setup() {
    const pageTitle = ref('Home page')

    const { menuItems } = useStationConfigInjection()

    const allDeparturesLink = reactive({
      name: ROUTE_NAME.DEPARTURES
    })

    usePageTitle(pageTitle)

    const { currentStationId } = useStationIdInjection()

    return {
      currentStationId,
      ICON_TYPE,
      allDeparturesLink,
      menuItems,
      ROUTE_NAME
    }
  }
})
