import { Market } from '@gomedia-apis-ts-pis/v1/api'

import { getDomain } from '@/helpers/domain'

enum TARGET {
  DEFAULT_BROWSER = '_system',
  MODAL_BROWSER = '_blank'
}

export const requestNavigation = (
  market: Market,
  currentStationId: string,
  locationId: string,
  locationName: string
): void => {
  const domain = getDomain()
  window.parent.postMessage(
    {
      type: 'gomedia.route.start',
      data: {
        locationId,
        locationName,
        servicePath: `${domain}/services/api/v1/navigation/${market}/${currentStationId}`
      }
    },
    '*'
  )
}

export const openExternalURL = (url: string): void => {
  window.parent.postMessage(
    {
      type: 'browser.open',
      data: {
        url,
        target: TARGET.DEFAULT_BROWSER,
        options: ''
      }
    },
    '*'
  )
}
