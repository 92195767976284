import { Market, TrainDeparture } from '@gomedia-apis-ts-pis/v1/api'
import { ref } from 'vue'

import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'
import { marketById } from '@/helpers/market-filter'

import store from '@/store'

export const useDepartures = (id: string) => {
  const isLoading = ref(true)
  const errorMessage = ref('')
  const departures = ref<TrainDeparture[]>([])
  const market: Market = marketById(id)

  const fetchDepartures = async () => {
    try {
      const res = await store.dispatch('requestAllDepartures', {
        market,
        id
      })
      if (!res.length) {
        throw new Error('No departures information available')
      }
      departures.value = res
    } catch (e) {
      errorMessage.value = e.message || 'An error has occurred'
      analytics.sendEvent(AnalyticsEvents.REQUEST_ERROR, {
        errorMessage: errorMessage.value
      })
    } finally {
      isLoading.value = false
    }
  }

  fetchDepartures()

  return {
    isLoading,
    errorMessage,
    departures
  }
}
