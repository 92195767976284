import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "facilities" }
const _hoisted_2 = { class: "page-container grid-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_subheader = _resolveComponent("a-subheader")!
  const _component_a_link_card = _resolveComponent("a-link-card")!
  const _component_a_async_loader = _resolveComponent("a-async-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_subheader, {
      title: _ctx.title,
      "hide-back-button": _ctx.redirectToFacilitiesPage
    }, null, 8, ["title", "hide-back-button"]),
    _createVNode(_component_a_async_loader, {
      "is-loading": _ctx.isLoading,
      "error-message": _ctx.errorMessage
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityCards, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_link_card, {
              key: index,
              "icon-type": item.iconType,
              title: item.title,
              inactive: item.inactive,
              to: item.to,
              "title-text-to-read": item.titleTextToRead,
              destinationId: item.destinationId,
              "warning-message": item.warningMessage,
              "navigation-icon-type": _ctx.ICON_TYPE.NAVIGATE_SM,
              "show-navigate-button": item.showNavigateButton && !_ctx.disableNavigation
            }, null, 8, ["icon-type", "title", "inactive", "to", "title-text-to-read", "destinationId", "warning-message", "navigation-icon-type", "show-navigate-button"]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["is-loading", "error-message"])
  ]))
}