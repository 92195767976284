import { computed, onBeforeUnmount, ref } from 'vue'

export enum PlayStatus {
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME'
}
const voiceConfig = {
  name: 'Daniel',
  lang: 'en-GB',
  volume: 1,
  rate: 1,
  pitch: 1
}

enum speechError {
  INTERRUPTED = 'interrupted'
}

export const useVoiceSpeech = () => {
  const textToSpeech = ref()
  const activeIndex = ref<number>()
  const nextStatus = ref<PlayStatus>()

  const voice = computed(() =>
    speechSynthesis
      .getVoices()
      .find(
        (voice) =>
          voice.name === voiceConfig.name ?? voice.lang === voiceConfig.lang
      )
  )

  speechSynthesis.cancel()

  function getUtterance() {
    const U = new SpeechSynthesisUtterance()
    U.text = textToSpeech.value
    U.voice = voice.value as SpeechSynthesisVoice
    U.lang = voiceConfig.lang
    U.volume = voiceConfig.volume
    U.rate = voiceConfig.rate
    U.pitch = voiceConfig.pitch

    U.onstart = () => {
      nextStatus.value = PlayStatus.PAUSE
    }
    U.onend = () => {
      nextStatus.value = PlayStatus.PLAY
    }
    U.onerror = (err) => {
      if (err.error !== speechError.INTERRUPTED) {
        console.error(err)
      }
    }

    return U
  }

  function convertTextToSpeech(text: string, i: number) {
    textToSpeech.value = text
    if (nextStatus.value === PlayStatus.PAUSE && activeIndex.value === i) {
      nextStatus.value = PlayStatus.RESUME
      speechSynthesis.pause()
    } else if (
      nextStatus.value === PlayStatus.RESUME &&
      activeIndex.value === i
    ) {
      // "fix" of the known speechSynthesis bug with Android Chrome
      // (https://github.com/mdn/browser-compat-data/issues/4500)
      if (!speechSynthesis.paused && !speechSynthesis.pending) {
        // restart speech synthesis if bug occurs
        speechSynthesis.cancel()
        setTimeout(() => {
          const U = getUtterance()
          speechSynthesis.speak(U)
        }, 100)
        return
      }
      // ----
      nextStatus.value = PlayStatus.PAUSE
      speechSynthesis.resume()
    } else {
      activeIndex.value = i
      speechSynthesis.cancel()
      setTimeout(() => {
        const U = getUtterance()
        speechSynthesis.speak(U)
      }, 100)
    }
  }
  onBeforeUnmount(() => speechSynthesis.cancel())
  return {
    convertTextToSpeech,
    activeIndex,
    nextStatus
  }
}
