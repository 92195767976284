
import { defineComponent } from 'vue'

import { navigateBack } from '@/helpers/route-history'

export default defineComponent({
  name: 'ABackButton',
  setup() {
    return {
      navigateBack
    }
  }
})
