
import AAsyncLoader from '@/components/a-async-loader/async-loader.vue'
import VideoPlayer from '@/components/a-video-player/video-player.vue'
import { VIDEO_TYPE } from '@/enums/videoType'
import { polling } from '@/helpers/polling'
import { navigateBack } from '@/helpers/route-history'
import { useStationIdInjection } from '@/hooks/useStationId'
import { DEFAULT_SRC, SignLanguageVideo, useVideos } from '@/hooks/useVideos'
import { computed, defineComponent, onBeforeUnmount, PropType, ref } from 'vue'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'

export default defineComponent({
  name: 'VideoGuide',
  components: {
    AAsyncLoader,
    VideoPlayer
  },
  props: {
    type: {
      type: String as PropType<VIDEO_TYPE>,
      required: true
    },
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    let stopPollingFunc: CallableFunction | null = null
    const requiredPollingVideoTypes = [
      VIDEO_TYPE.DEPARTURE,
      VIDEO_TYPE.ANNOUNCEMENT
    ]

    const videos = ref<Array<SignLanguageVideo>>([])
    const index = ref<number>(0)
    const videoKeyIngredient = ref<number>(0)
    const activeVideo = ref<SignLanguageVideo>()
    const { market, currentStationId } = useStationIdInjection()
    const { isLoading, fetchVideos, errorMessage } = useVideos(
      currentStationId,
      market,
      props.type,
      props.id
    )

    const totalVideoLength = computed((): number => videos.value.length)
    const videoPlayerKey = computed((): string => {
      if (!activeVideo.value) {
        return videoKeyIngredient.value.toString()
      }
      return `${activeVideo.value.id}-${activeVideo.value.subtitle}-${videoKeyIngredient.value}`
    })
    const pageErrorMessage = computed((): string => {
      const wasVideosFetched = totalVideoLength.value
      if (wasVideosFetched) {
        return ''
      }
      return errorMessage.value
    })

    const analyticsData = computed(() => ({
      videoId: activeVideo.value?.id,
      videoLink: activeVideo.value?.link,
      type: props.type,
      index: index.value
    }))

    function onVideoPlayed() {
      analytics.sendEvent(AnalyticsEvents.VIDEO_PLAYED, analyticsData.value)
    }

    function handleClose() {
      analytics.sendEvent(
        AnalyticsEvents.SIGN_LANGUAGE_CLOSED,
        analyticsData.value
      )
      navigateBack()
    }

    function changeKeyIngredient() {
      videoKeyIngredient.value = videoKeyIngredient.value + 1

      if (activeVideo.value) {
        analytics.sendEvent(AnalyticsEvents.VIDEO_READY, analyticsData.value)
      }
    }

    function prev() {
      index.value--
      changeActiveVideo()
    }

    function next() {
      index.value++
      changeActiveVideo()
    }
    function changeActiveVideo() {
      if (!videos.value.length) {
        return
      }
      activeVideo.value = videos.value[index.value]

      if (activeVideo.value.link === DEFAULT_SRC) {
        analytics.sendEvent(AnalyticsEvents.VIDEO_WAITING, analyticsData.value)
      }
    }

    const hidePrevBtn = computed((): boolean => index.value < 1)
    const hideNextBtn = computed(
      (): boolean => index.value + 1 >= totalVideoLength.value
    )

    function updateVideos(newVideos: SignLanguageVideo[]): void {
      let isRefreshRequired = false
      videos.value = videos.value.map((item) => {
        const newVideo = newVideos.find((v) => v.id === item.id)
        if (!newVideo || item.link === newVideo.link) {
          return item
        }

        const isDefaultVideo = newVideo.link === DEFAULT_SRC
        const isCurrentActiveVideo = activeVideo.value?.id === newVideo.id

        if (isCurrentActiveVideo && !isDefaultVideo) {
          isRefreshRequired = true
        }

        return { ...newVideo }
      })

      if (isRefreshRequired) {
        changeActiveVideo()
        changeKeyIngredient()
      }
    }

    function runPollingFunc() {
      stopPollingFunc = polling(async () => {
        const newVideos = await fetchVideos()
        updateVideos(newVideos)
      })
    }

    async function init() {
      videos.value = await fetchVideos()
      if (requiredPollingVideoTypes.includes(props.type)) {
        runPollingFunc()
      }
      changeActiveVideo()
    }

    init()

    onBeforeUnmount(() => {
      if (stopPollingFunc) {
        stopPollingFunc()
      }
    })

    return {
      navigateBack,
      prev,
      next,
      activeVideo,
      hidePrevBtn,
      hideNextBtn,
      index,
      totalVideoLength,
      isLoading,
      pageErrorMessage,
      videoPlayerKey,
      handleClose,
      onVideoPlayed
    }
  }
})
