export class BrowserStorage {
  public key
  public value
  public lsSupport

  constructor() {
    this.lsSupport = this.isLsSupported()
    this.key = null
    this.value = null
  }

  public isLsSupported() {
    try {
      if (localStorage) {
        return true
      }
    } catch (e) {
      console.log('Local storage disabled')
    }

    return false
  }

  public validateData() {
    if (typeof this.value === 'object') {
      this.value = JSON.stringify(this.value)
    }

    if (typeof this.value !== 'undefined' && this.value !== null) {
      if (this.lsSupport) {
        localStorage.setItem(this.key, this.value)
      } else {
        this.createCookie(this.key, this.value, 5)
      }
    }

    // No value supplied, return value
    if (typeof this.value === 'undefined') {
      let currentData: any = ''

      if (this.lsSupport) {
        // Native support
        currentData = localStorage.getItem(this.key)
      } else {
        // Use cookie
        currentData = this.readCookie(this.key)
      }
      try {
        currentData = JSON.parse(currentData)
      } catch (e) {
        // console.log('err', e)
      }

      // If returns false means localStorage is more than 5 days and will delete the entry
      if (currentData == null) {
        if (this.lsSupport) {
          localStorage.removeItem(this.key)
        } else {
          this.createCookie(this.key, '', -1)
        }
        return false
      }

      return currentData.data
    }
    // Null specified, remove store
    if (this.value === null) {
      if (this.lsSupport) {
        localStorage.removeItem(this.key)
      } else {
        this.createCookie(this.key, '', -1)
      }
    }

    return false
  }

  public save(key, value) {
    this.key = key
    this.value = { data: value }

    return this.validateData()
  }

  public read(key) {
    this.key = key
    this.value = undefined
    return this.validateData()
  }

  public createCookie(key, value, exp: number | Date, path?) {
    let date
    let expires
    if (typeof exp === 'number') {
      date = new Date()
      date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000)
    } else {
      date = exp
    }
    if (date) {
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie =
      key + '=' + value + (expires || '') + '; path=' + (path || '/')
  }

  public readCookie(key) {
    const nameEQ = key + '='
    const ca = document.cookie.split(';')
    for (let i = 0, max = ca.length; i < max; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }
    return null
  }

  public compareDates(oldDate) {
    const newDate: any = new Date()

    const FiveDays = 60 * 60 * 1000 * 5
    const compareData: number = oldDate || 0

    return newDate - compareData < FiveDays
  }

  public get(name: string) {
    const r = this.readCookie(name)

    if (r === undefined || r === null) {
      return
    }
    return decodeURIComponent(r)
  }

  public put(
    name: string,
    value: string | boolean | number,
    options: { expires: Date; path?: string }
  ) {
    this.createCookie(
      name,
      encodeURIComponent(value),
      options.expires,
      options.path
    )
  }

  public putObject(
    name: string,
    value: string | boolean | number,
    options: { expires: Date; path?: string }
  ) {
    this.createCookie(
      name,
      JSON.stringify(value),
      options.expires,
      options.path
    )
  }

  public getObject(name: string) {
    const r = this.get(name)

    if (r) {
      try {
        return JSON.parse(r)
      } catch (e) {
        return r
      }
    }
  }

  public remove(name: string) {
    // Note - the expires date is Now, which is instantly in the past. We do not need to set a value -
    // the browser removes the cookie
    this.put(name, '', { expires: new Date() })
  }
}

export const storage = new BrowserStorage()
