export enum AnalyticsEvents {
  REQUEST_ERROR = 'REQUEST_ERROR',
  CONFIRM_NAVIGATION = 'CONFIRM_NAVIGATION',
  DECLINE_NAVIGATION = 'DECLINE_NAVIGATION',
  PAGE_VIEW = 'PAGE_VIEW',
  NAVIGATION_ITEM_CLICKED = 'NAVIGATION_ITEM_CLICKED',
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
  SIGN_LANGUAGE_OPENED = 'SIGN_LANGUAGE_OPENED',
  VIDEO_WAITING = 'VIDEO_WAITING',
  VIDEO_READY = 'VIDEO_READY',
  VIDEO_PLAYED = 'VIDEO_PLAYED',
  SIGN_LANGUAGE_CLOSED = 'SIGN_LANGUAGE_CLOSED'
}
