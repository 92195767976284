import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

import { FacilityType } from '@/enums/facilities'
import { analytics } from '@/services/analytics'
import { AnalyticsEvents } from '@/enums/analyticsEvents'

import DefaultLayout from '@/layouts/default.vue'
import ClearLayout from '@/layouts/clear.vue'
import WebLayout from '@/layouts/web.vue'
import ErrorLayout from '@/layouts/error.vue'

import Home from '@/views/home/index.vue'
import Departures from '@/views/departures/index.vue'
import Directions from '@/views/directions/index.vue'
import StationFacilities from '@/views/station-facilities/index.vue'
import StationAnnouncements from '@/views/station-announcements/index.vue'
import NavigationConfirmation from '@/views/navigation-confirmation/index.vue'
import Journey from '@/views/journey/index.vue'
import InfoPage from '@/views/info-page/index.vue'
import VideoGuide from '@/views/video-guide/index.vue'
import { ROUTE_NAME } from '@/enums/routeNames'
import { getStationConfig } from '@/hooks/useStationConfig'
import { marketById } from '@/helpers/market-filter'

export { ROUTE_NAME }

export const ROUTE_PARAMS = {
  CURRENT_STATION_ID: 'currentStationId',
  [ROUTE_NAME.NAVIGATION_CONFIRMATION]: {
    DESTINATION_ID: 'destinationID',
    DESTINATION_NAME: 'destinationName'
  },
  [ROUTE_NAME.INFO_PAGE]: {
    PAGE_ID: 'pageId',
    TYPE: 'type'
  },
  [ROUTE_NAME.JOURNEY]: {
    STATION_ID: 'stationId',
    PLATFORM: 'platform',
    TIME: 'time'
  },
  [ROUTE_NAME.VIDEO_GUIDE]: {
    TYPE: 'type',
    ID: 'id'
  }
} as const

const routes: Array<RouteRecordRaw> = [
  {
    path: `/web/:${ROUTE_PARAMS.CURRENT_STATION_ID}`,
    component: WebLayout,
    children: [
      {
        path: '',
        redirect: () => ({ name: ROUTE_NAME.WEB_DEPARTURES })
      },
      {
        path: 'departures',
        name: ROUTE_NAME.WEB_DEPARTURES,
        component: Departures
      },
      {
        path: `journey/:${ROUTE_PARAMS.JOURNEY.PLATFORM}/:${ROUTE_PARAMS.JOURNEY.STATION_ID}`,
        name: ROUTE_NAME.WEB_JOURNEY,
        component: Journey,
        props: true
      }
    ]
  },
  {
    path: `/:${ROUTE_PARAMS.CURRENT_STATION_ID}`,
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: ROUTE_NAME.HOME,
        component: Home
      },
      {
        path: 'departures',
        name: ROUTE_NAME.DEPARTURES,
        component: Departures
      },
      {
        path: `journey/:${ROUTE_PARAMS.JOURNEY.PLATFORM}/:${ROUTE_PARAMS.JOURNEY.STATION_ID}`,
        name: ROUTE_NAME.JOURNEY,
        component: Journey,
        props: true
      },
      {
        path: 'station-facilities',
        name: ROUTE_NAME.STATION_FACILITIES,
        component: StationFacilities,
        props: {
          type: FacilityType.FACILITY,
          title: 'Station facilities'
        }
      },
      {
        path: 'station-announcements',
        name: ROUTE_NAME.STATION_ANNOUNCEMENTS,
        component: StationAnnouncements,
        props: {
          title: 'Station announcements'
        }
      },
      {
        path: 'shops',
        name: ROUTE_NAME.SHOPS,
        component: StationFacilities,
        props: {
          type: FacilityType.SHOP,
          title: 'Food, drinks & shops'
        }
      },
      {
        path: `info/:${ROUTE_PARAMS.INFO_PAGE.TYPE}/:${ROUTE_PARAMS.INFO_PAGE.PAGE_ID}`,
        name: ROUTE_NAME.INFO_PAGE,
        component: InfoPage,
        props: true
      }
    ]
  },
  {
    path: `/:${ROUTE_PARAMS.CURRENT_STATION_ID}`,
    component: ClearLayout,
    children: [
      {
        path: 'directions',
        name: ROUTE_NAME.DIRECTIONS,
        component: Directions
      },
      {
        path: `navigation-confirmation/:${ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_NAME}/:${ROUTE_PARAMS.NAVIGATION_CONFIRMATION.DESTINATION_ID}`,
        name: ROUTE_NAME.NAVIGATION_CONFIRMATION,
        component: NavigationConfirmation
      },
      {
        path: `video-guide/:${ROUTE_PARAMS.VIDEO_GUIDE.TYPE}/:${ROUTE_PARAMS.VIDEO_GUIDE.ID}?`,
        name: ROUTE_NAME.VIDEO_GUIDE,
        component: VideoGuide,
        props: true
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: ErrorLayout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  if (to.name === ROUTE_NAME.HOME) {
    const stationId = to.params[ROUTE_PARAMS.CURRENT_STATION_ID] as string
    const market = marketById(stationId)
    const { redirectToFacilitiesPage } = getStationConfig(market, stationId)
    if (redirectToFacilitiesPage) {
      next({
        name: ROUTE_NAME.STATION_FACILITIES,
        params: {
          [ROUTE_PARAMS.CURRENT_STATION_ID]: stationId
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.name !== store.getters.lastRouteName) {
    store.dispatch('updateRouteHistory', to)
  }
  analytics.sendEvent(AnalyticsEvents.PAGE_VIEW)
})

export default router
