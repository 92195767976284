
import { computed, defineComponent, onMounted, ref } from 'vue'
import { DEFAULT_SRC } from '@/hooks/useVideos'

const FORWARD_TIME = 5

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    src: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    let videoPlayer: HTMLVideoElement
    const videoPlayerRef = ref<HTMLVideoElement>()
    const isPaused = ref<boolean>(false)

    const playPauseButtonTitle = computed<string>(() =>
      isPaused.value ? 'Play' : 'Pause'
    )

    function isDefaultVideo() {
      return props.src === DEFAULT_SRC
    }

    function onPlayPauseClick() {
      if (videoPlayer.paused) {
        isPaused.value = false
        videoPlayer.play()
        return
      }
      isPaused.value = true
      videoPlayer.pause()
    }

    function onForward() {
      videoPlayer.currentTime += FORWARD_TIME
    }

    function onRewind() {
      videoPlayer.currentTime -= FORWARD_TIME
    }

    function onPlayed() {
      if (!isDefaultVideo()) {
        emit('video-played')
      }
    }

    function init() {
      if (!videoPlayerRef.value) {
        console.error('No video player found')
        return
      }
      videoPlayer = videoPlayerRef.value
    }

    onMounted(() => {
      init()
    })
    return {
      videoPlayerRef,
      isPaused,
      playPauseButtonTitle,
      onPlayPauseClick,
      onRewind,
      onForward,
      onPlayed,
      FORWARD_TIME
    }
  }
})
