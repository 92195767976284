import { Environment } from '@/enums/environment'
import { getDomain } from '@/helpers/domain'

export const getEnvironment = (): Environment => {
  const domain = getDomain()

  if (domain.includes('vin.cloud')) {
    return Environment.PRD
  }

  if (domain.includes('vin.staging')) {
    return Environment.STG
  }

  if (domain.includes('vin.dev')) {
    return Environment.DEV
  }

  return Environment.LOCAL
}
