
import { computed, defineComponent, PropType } from 'vue'
import { DisplayCallingPoint } from '@gomedia-apis-ts-pis/v1/api'

import {
  getCallingPointId,
  getFormattedTimes,
  getNameI18n
} from '@/helpers/departures'
import { useStationIdInjection } from '@/hooks/useStationId'
import { useLayoutConfigInjection } from '@/hooks/useLayoutConfig'
import { useStationConfigInjection } from '@/hooks/useStationConfig'

import AJourneyItem from '@/components/a-journey-item/journey-item.vue'

type JourneyItem = {
  id: string
  title: string
  platform: string
  allowNavigation: boolean
  scheduledTime: string
  actualTime?: string
}

export default defineComponent({
  name: 'AJourneyList',
  components: { AJourneyItem },
  props: {
    callingPoints: {
      type: Array as PropType<DisplayCallingPoint[]>,
      required: true
    },
    platform: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { currentStationId } = useStationIdInjection()
    const { allowJourneyNavigation } = useLayoutConfigInjection()
    const { disableNavigation } = useStationConfigInjection()
    const lastIndex = computed(() => props.callingPoints.length - 1)
    const currentStationIndex = computed(() =>
      props.callingPoints.findIndex(
        (item) => getCallingPointId(item) === currentStationId
      )
    )
    const journeyItems = computed<JourneyItem[]>(() =>
      props.callingPoints.map((item, index) => {
        const displayTime =
          index === lastIndex.value
            ? item.calling_point?.arrival_times
            : item.calling_point?.departure_times
        const { scheduledTime, actualTime } = getFormattedTimes(
          displayTime,
          item.display_time_zone
        )
        const allowNavigation =
          !disableNavigation &&
          allowJourneyNavigation &&
          props.platform !== '-' &&
          index > currentStationIndex.value

        return {
          actualTime,
          scheduledTime,
          allowNavigation,
          platform: `Platform ${props.platform}`,
          id: getCallingPointId(item),
          title: getNameI18n(item.display_name)
        }
      })
    )

    return {
      journeyItems
    }
  }
})
